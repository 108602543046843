import { Burger } from '../core'
import '../plugins/fixed-header'

export default {
  burger: undefined,
  status: {
    scrolled: false,
    fixed: false,
  },

  init() {
    let self = this,
    $body = $('body'),
    $app = $('.app'),
    $burger = $('.burger')

    /* Burger */
    let scrollTopBefore = 0, burgerTimeout = undefined
    this.burger = new Burger('.burger', {
      onToggle: function() {
        let windowHeight = window.innerHeight
        clearTimeout(burgerTimeout)
        if (self.burger.isExpanded) {
          $body.addClass('--menu-open')
          windowHeight = window.innerHeight
          burgerTimeout = setTimeout(function() {
            scrollTopBefore = $(window).scrollTop()
            $app.css({
              height: windowHeight + 'px',
              overflow: 'hidden',
            })
          }, 350)
        } else {
          $app.css({
            height: '',
            overflow: '',
          })
          $body.removeClass('--menu-open').scrollTop(scrollTopBefore)
        }
      },
    })

    /* Responsive toggler */
    $.mlmi.mobile.addCallbacks(function() {
      /* Mobile */
      requestAnimationFrame(function() {
        $body.removeClass('--menu-open --header-fixed --header-visible --header-hidden')
      })
    }, function() {
      /* Desktop */
      requestAnimationFrame(function() {
        $body.removeClass('--menu-open --mobile-header-fixed --mobile-header-visible --mobile-header-hidden')
        $body.css({
          height: '',
          overflow: '',
        })
        $burger.attr('aria-expanded', 'false')
      })
    })

    /* Fixed header */
    $('.header').FixedHeader('header')
    $('.mobile-header').FixedHeader('mobile-header')

    /* Submenu hover */
    $('.main-menu__item--parent').on('mouseenter', function() {
      if (!$('html').hasClass('has-touch')) {
        $('body').addClass('--submenu-hover')
      }
    }).on('mouseleave', function() {
      if (!$('html').hasClass('has-touch')) {
        $('body').removeClass('--submenu-hover')
      }
    })
    $('.main-menu__item--parent > a').on('click', function() {
      if ($('html').hasClass('has-touch')) {
        if (!$('body').hasClass('--submenu-hover')) {
          $('body').addClass('--submenu-hover')
        } else {
          $('body').removeClass('--submenu-hover')
        }
        return false;
      }
    })
    $('.main-menu__item--parent a').on('click', function() {
      if (!$('html').hasClass('has-touch')) {
        $('body').addClass('--submenu-active')
      }
    })
  },
}
