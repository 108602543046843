$.fn.FixedHeader = function(name) {
  let self = this
  let $body = $('body')

  const FIXED = '--' + name + '-fixed'
  const VISIBLE = '--' + name + '-visible'
  const HIDDEN = '--' + name + '-hidden'

  self.status = {
    currentScroll: $(window).scrollTop(),
    previousScroll: $(window).scrollTop(),
    scrolledPastHeader: false,
    visibleFixed: false,
    direction: 'down',
    timeout: undefined,
  }

  self.checkScroll = function() {
    /* calculate status */
    self.status.currentScroll = $(window).scrollTop()
    self.status.scrolledPastHeader = self.status.currentScroll > self.outerHeight(false)
    self.status.direction = self.status.currentScroll > self.status.previousScroll ? 'down' : 'up'
    self.status.visibleFixed = (self.status.direction == 'up' && (self.status.visibleFixed || self.status.scrolledPastHeader) && self.status.currentScroll > 0) || (self.status.direction == 'down' && self.status.visibleFixed && !self.status.scrolledPastHeader)

    /* display status */
    if (self.status.visibleFixed) {
      $body.addClass(FIXED).addClass(VISIBLE)
    } else if (self.status.scrolledPastHeader) {
      if ($body.hasClass(FIXED)) {
        $body.addClass(FIXED).removeClass(VISIBLE)
      } else {
        $body.addClass(FIXED).addClass(HIDDEN).removeClass(VISIBLE)
        setTimeout(function() {
          $body.removeClass(HIDDEN)
        }, 60)
      }
    } else {
      $body.removeClass(FIXED).removeClass(VISIBLE)
    }

    /* remember direction */
    self.status.previousScroll = self.status.currentScroll
  }

  return function() {
    $.mlmi.mobile.addCallbacks(function() {
      if (name == 'mobile-header') {
        $(window).off('scroll resize orientationchange', self.checkScroll)
        $(window).on('scroll resize orientationchange', self.checkScroll)
        self.checkScroll()
      } else {
        $(window).off('scroll resize orientationchange', self.checkScroll)
      }
    }, function() {
      if (name == 'header') {
        $(window).off('scroll resize orientationchange', self.checkScroll)
        $(window).on('scroll resize orientationchange', self.checkScroll)
        self.checkScroll()
      } else {
        $(window).off('scroll resize orientationchange', self.checkScroll)
      }
    })
    return self
  }()
}
