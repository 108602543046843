/*
* Custom media query checker
*/
export default function () {
  let obj = this;
  this.isOn = -1;
  this.offCallbacks = [];
  this.onCallbacks = [];

  /* Check mobile on resize */
  this.resized = function()	{
		if ((obj.isOn === -1 || !obj.isOn) && $('.customRuleChecker').is(':visible')) {
			obj.isOn = true;
      obj.callOn();
      $(window).trigger('toggle');
		} else if ((obj.isOn === -1 || obj.isOn) && !$('.customRuleChecker').is(':visible')) {
			obj.isOn = false;
      obj.callOff();
      $(window).trigger('toggle');
		}
	};

  /* Call callbacks */
  obj.callOff = function() {
    obj.offCallbacks.forEach(function(callback) {
      callback();
    });
  };

  obj.callOn = function() {
    obj.onCallbacks.forEach(function(callback) {
      callback();
    });
  };

  /* Add callbacks */
  this.addCallbacks = function(_onCallback, _offCallback, _autoRun) {
    obj.offCallbacks.push(_offCallback);
    obj.onCallbacks.push(_onCallback);
    if (_autoRun === undefined || _autoRun == true) {
      if (obj.isOn === true) {
        _onCallback()
      } else if (obj.isOn === false) {
        _offCallback()
      }
    }
    return obj;
  };

  this.kill = function() {
    this.offCallbacks = [];
    this.onCallbacks = [];
    $(window).off('resize orientationchange load', obj.resized);
  };

  /* Initializer */
  $(window).on('resize orientationchange load', obj.resized);
  obj.resized();
  return this;
}
