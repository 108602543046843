$.fn.Graph01 = function() {
  let self = this
  // self.animation = $.mlmi.animations[$(this).find('.animation').data('name')]
  //
  // self.calculateHeight = function() {
  //   self.css({
  //     'min-height': '0',
  //     'height': $.mlmi.mobile.isMobile ? '' : '54rem',
  //   })
  // }
  //
  // self.scrolled = function(top) {
  //   if ($.mlmi.mobile.isMobile) {
  //     self.find('.text-content:first').css('height', '')
  //   } else {
  //     self.find('.text-content:first').height(self.find('.text-content:last').height())
  //   }
  //   let divideBy = $.mlmi.mobile.isMobile ? 5 : 12,
  //   currentFrame = Math.ceil($(window).scrollTop() / divideBy)
  //   if (currentFrame < 0) {
  //     currentFrame = 0
  //   } else if (currentFrame > self.animation.totalFrames) {
  //     currentFrame = self.animation.totalFrames
  //   }
  //   self.animation.goToAndStop(currentFrame, true)
  //   if (currentFrame >= 38) {
  //     self.addClass('graph-01-container--active')
  //   } else {
  //     self.removeClass('graph-01-container--active')
  //   }
  // }
  //
  // self.mobile = function() {
  //   $(window).off('load resize orientationchange', self.calculateHeight)
  //   self.calculateHeight()
  // }
  //
  // self.desktop = function() {
  //   self.find('.animation').parent().FixedContent({
  //     bottomBlocker: $('#growth'),
  //   })
  //   self.find('.col:first .text-content').FixedContent({
  //     bottomBlocker: $('#growth'),
  //   })
  //   $(window).on('load resize orientationchange', self.calculateHeight)
  //   self.calculateHeight()
  // }

  self.init = function() {
    /* Smooth scroll from graph 01 */
    $('.animation__graph-01__link').on('click', function() {
      let targetTop = $($(this).attr('href')).find('h2:first').offset().top;
      if ($.mlmi.mobile.isMobile) {
        targetTop -= $('.mobile-header').outerHeight(false) + 24
      } else {
        targetTop -= $('.header').outerHeight(false) / 2
      }
      $('html, body').scrollTo(targetTop, 750, 5, 'easeInOutQuart')
      return false
    })
    // $.mlmi.scroller.addCallback(self.scrolled)
    // self.scrolled()
  }

  return function() {
    self.init()
    // $.mlmi.mobile.addCallbacks(self.mobile, self.desktop)
    return self
  }()
}

$.fn.Graph02 = function() {
  let self = this
  self.animation = $.mlmi.animations[self.data('name')]

  self.scrolled = function(top) {
    let currentFrame = -(Math.ceil(self.offset().top - top + 120 - $(window).height()) / 8)
    if (currentFrame < 0) {
      currentFrame = 0
    } else if (currentFrame > self.animation.totalFrames) {
      currentFrame = self.animation.totalFrames
    }
    self.animation.goToAndStop(currentFrame, true)
  }

  return function() {
    $.mlmi.scroller.addCallback(self.scrolled)
    return self
  }()
}

export default {
  init() {
    if ($('.animation--graph-01, .animation--graph-01-fr').length) {
      $('.graph-01-container').Graph01()
    }
    $('.animation--graph-02, .animation--graph-02, .page-services .animation').each(function() {
      $(this).Graph02()
    })
    return this
  },
}
