import '../plugins/picture-ratio'

$.fn.Diagonals = function() {
  let self = this
  self.currentImageScroll = 0

  self.el = {
    diagonals: self.find('.home-diagonal'),
    container: $('<div>').addClass('diagonal-images'),
    images: $('<div>').addClass('diagonal-images__pictures'),
    mask: $('<div>').addClass('diagonal-images__mask'),
  }

  self.build = function() {
    self.el.mask.append('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 410 680" xml:space="preserve"><path id="diagonal-path" fill="#F5F9FF" d="M0 0h410L0 681.2V0"/></svg>')
    self.el.container.append(self.el.images).append(self.el.mask)
    self.after(self.el.container)
    self.el.diagonals.each(function() {
      self.el.images.append($(this).find('.text-content picture:last').clone())
    })
    self.el.images.find('picture').each(function() {
      $(this).PictureRatio()
    })
  }

  self.diagonalScroll = function() {
    let targetImageScroll = 0
    if ($('body').hasClass('--sections-lock')) {
      self.el.images.find('.picture').each(function() {
        $(this).css('height', $(window).height() - $('.header').outerHeight(false) + 'px')
      })
    }
    self.find('.fp-section.active').prevAll('.page-section, .footer').each(function() {
      let targetHeight = $(this).next().outerHeight(false) - $('.header').outerHeight(false)
      targetImageScroll += targetHeight
    })
    if (self.currentImageScroll != targetImageScroll) {
      self.currentImageScroll = targetImageScroll
      self.el.images.css({
        transform: 'translateY(-' + targetImageScroll + 'px)',
      })
    }
  }

  return function() {
    self.build()
    $(window).on('load resize orientationchange', self.diagonalScroll)
    self.diagonalScroll()
    return self
  }()
}

export default {
  diagonals: undefined,

  init() {
    if ($('.home-diagonal').length) {
      this.diagonals = $('main').Diagonals()
    }
    return this
  },

  scroll() {
    if (this.diagonals != undefined) {
      window.requestAnimationFrame(this.diagonals.diagonalScroll)
    }
  },

  destroy() {
    if ('fullpage_api' in window) {
      fullpage_api.destroy('all')
    }
  },
}
