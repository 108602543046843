import { Mobile, AjaxifyGrid_CoreInit } from './core'
import CustomChecker from './modules/custom'
import Header from './modules/header'
import Diagonals from './modules/diagonals'
import Animations from './modules/animations'
import './plugins/fixed-content'
import './plugins/picture-ratio'
import './plugins/google-map'

AjaxifyGrid_CoreInit()

$(function() {
  /* Globals */
  $.mlmi = {
    mobile: new Mobile(),
    custom: new CustomChecker(),
    scroller: $(window).ScrollEvents(),
    animations: [],
  }

  /* Prevent empty anchor clicks */
  $('a[href="#"]').on("click", function() {
    return false
  })

  /* Header */
  Header.init()

  /* Diagonals */
  const diagonals = Diagonals.init()

  /* Content modifiers */
  $('.fixed-content').each(function() {
    $(this).find('.text-content:first').FixedContent()
  })

  /* Animations */
  let animations = {}
  $('.animation').each(function() {
    let animationName = $(this).data('name'),
    animation = bodymovin.loadAnimation({
      container: $(this).get(0),
      renderer: 'svg',
      loop: false,
      autoplay: $(this).data('name') == 'graph-01' || $(this).data('name') == 'graph-01-fr',
      path: '/img/animations/' + animationName + '.json',
    })
    $.mlmi.animations[animationName] = animation
  })

  /* Animations */
  Animations.init()

  /* Locked sections */
  if ($('body').hasClass('--sections-lock')) {
    $.mlmi.custom.addCallbacks(function() {
      $('main').fullpage({
        autoScrolling: true,
        sectionSelector: '.page-section, .footer',
        paddingTop: $('body').hasClass('page-services') ? $('.header').outerHeight(false) + 'px' : '0px',
        paddingBottom: '0px',
        easingcss3: 'cubic-bezier(0.65, 0, 0.35, 1)',
        onLeave: function(origin, destination, direction) {
          diagonals.scroll()
          if ($(destination.item).find('.animation').length) {
            let animationName = $(destination.item).find('.animation').data('name'),
            animation = $.mlmi.animations[animationName]
            if (animation.currentFrame <= 1) {
              $.mlmi.animations[animationName].goToAndPlay(1, true)
            }
          }
        },
        afterResize: function() {
          diagonals.scroll()
        },
        licenseKey: 'CFC23AB8-49CB4B49-9B02ACA9-29F3AA72',
      })
    }, function() {
      diagonals.destroy()
    })
  }

  /* Picture ratio */
  $('.case-study-image .picture').PictureRatio()
  $('.services .page-section:first picture').PictureRatio()

  /* Investment toggle */
  $('.investment').each(function() {
    let self = $(this)
    self.content = self.find('.investment__infos')
    self.button = self.find('.investment__button')
    self.toggle = function() {
      if (self.content.attr('aria-hidden') === 'true') {
        self.content.attr('aria-hidden', 'false')
        self.attr('aria-expanded', 'true')
      } else {
        self.content.attr('aria-hidden', 'true')
        self.attr('aria-expanded', 'false')
      }
    }
    self.button.on('click', self.toggle)
  })

  /* Contact map */
  $('.contact-map').each(function() {
    $(this).ContactMap()
  })

  /* Team member scroller */
  $('.single-team-member').each(function() {
    $.mlmi.scroller.add(function(top) {
      if ($.mlmi.mobile.isMobile) {
        $('.diagonal-images').css('top', '')
      } else {
        let targetTop = 0,
        limitBottom  = $('.footer').offset().top
        if (limitBottom - top < $(window).height()) {
          targetTop -= $(window).height() - (limitBottom - top)
        }
        if (targetTop < -top) {
          targetTop = -top
        }
        $('.diagonal-images').css('top', targetTop + 'px')
      }
    })
  })

  /* Services */
  setTimeout(function() {
    $('.page-services .icon-angle').animate({
      opacity: 1,
    }, 750)
  }, 6600)
})
