$.fn.PictureRatio = function() {
  let self = this
  self.image = self.find('img')

  self.checkRatio = function() {
    if (!self.image.length) {
      return
    }
    if (!self.image.get(0).naturalWidth) {
      self.image.on('load', function() {
        self.checkRatio()
      })
    } else {
      let selfRatio = self.outerWidth(false) / self.outerHeight(false),
      imgRatio = self.image.get(0).naturalWidth / self.image.get(0).naturalHeight
      if (imgRatio > selfRatio) {
        self.addClass('picture--ratio-vertical')
      } else {
        self.removeClass('picture--ratio-vertical')
      }
    }
  }

  return function() {
    $(window).on('load orientationchange resize', self.checkRatio)
    self.checkRatio()
    return self
  }()
}
