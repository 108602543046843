$.fn.FixedContent = function(options) {
  let self = this
  self.use_stretch = self.parent().hasClass('stretch-content')
  self.options = $.fn.extend({
    topBlocker: $('.header'),
    bottomBlocker: $('.footer'),
    container: self.parent(),
    attached: undefined,
  }, options)

  self.calculatePosition = function() {
    /* Calculate positions */
    let containerPaddingX = parseFloat(self.options.container.css('padding-left')),
    containerPaddingY = parseFloat(self.options.container.css('padding-top')),
    targetLeft = self.options.container.offset().left + containerPaddingX,
    targetWidth = self.options.container.outerWidth(false) - (containerPaddingX * 2),
    targetTop = self.options.container.offset().top + containerPaddingY

    /* Offset for top element */
    let topOffset = $(window).scrollTop()
    if (topOffset < 0) {
      topOffset = 0
    } else if (topOffset > self.options.topBlocker.outerHeight(false)) {
      topOffset = self.options.topBlocker.outerHeight(false)
    }
    targetTop -= topOffset

    /* Offset for bottom element */
    let currentBottomContent = targetTop + self.outerHeight(false),
    currentTopFooter = self.options.bottomBlocker.offset().top - $(window).scrollTop()
    if ($('body').hasClass('single-team-member')) {
      currentTopFooter = 0
    }
    if (currentTopFooter - currentBottomContent < containerPaddingY) {
      targetTop -= (containerPaddingY - (currentTopFooter - currentBottomContent))
    }

    /* Fix on stretch */
    if (self.use_stretch) {
      targetTop = 0;
      if (currentTopFooter < $(window).height()) {
        targetTop -= ($(window).height() - currentTopFooter)
      }
      targetTop += 'px'
      targetLeft += 'px'
      targetWidth = '50vw'
      targetHeight = '100vh'
    } else {
      targetTop += 'px'
      targetLeft += 'px'
      targetWidth += 'px'
      targetHeight = 'auto'
    }

    /* Position content */
    self.css({
      top: targetTop,
      left: targetLeft,
      width: targetWidth,
      height: targetHeight,
      position: 'fixed',
    })
  }

  self.destroy = function() {
    self.css({
      top: '',
      left: '',
      width: '',
      height: '',
      position: '',
    })
    $.mlmi.scroller.removeCallback(self.calculatePosition)
  }

  return function() {
    $.mlmi.mobile.addCallbacks(function() {
      self.destroy()
    }, function() {
      $.mlmi.scroller.addCallback(self.calculatePosition)
      self.calculatePosition()
    })
    return self
  }()
}
